import FadeInOnScroll from "../../FadeInOnScroll";
import "../Turnier/Turnier.css";
import CommunityArticles from "./CommunityArticles";
import CommunityTeaser from "./CommunityTeaser";


function Community() {
    return (

        <div>
            <FadeInOnScroll>
            <CommunityTeaser></CommunityTeaser>
            </FadeInOnScroll>
            <FadeInOnScroll>
                <CommunityArticles></CommunityArticles>
            </FadeInOnScroll>

        </div>
    );
}

export default Community;