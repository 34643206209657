import RedBullLogo from '../images/Red-Bull-logo.png';

function SponsorenTeaser() {
    return(
        <div class="container mt-5 mb-5">
            <div class="desktop">
            <div class="row">
                <div class="col-5 align-content-center">
                    <div class="card-img-left">
                        <img src={RedBullLogo}></img>
                    </div>
                </div>
                <div class="col-6 TurnierText align-content-center text-start ms-3">
                    <h4 class="gam-ts1"><strong>Die Sponsoren</strong></h4>
                    <p class="gam-ts mt-3">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                </div>
            </div>
            </div>
            <div class="mobile">
            <div class="row text-center align-self-center align-content-center justify-content-end">
                <div class="col align-content-center text-center align-self-center">
                    <div class="card-img-left align-self-center text-center desktop">
                        <img src={RedBullLogo}></img>
                    </div>
                </div>
                </div>
                <div class="row mt-5">
                <div class="col TurnierText align-content-center text-center">
                    <h4 class="gam-ts1 text-center"><strong>Die Sponsoren</strong></h4>
                    <p class="gam-ts mt-3">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                </div>
            </div>
            </div>
        </div>

    );
}

export default SponsorenTeaser;