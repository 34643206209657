import FadeInOnScroll from "../../FadeInOnScroll";
import Eventbody from "./Eventbody";
import "./Events.css";
import Timetable from "./Timetable";


function Events() {
    return (

        <div>
            <FadeInOnScroll>
            <Timetable></Timetable>
            </FadeInOnScroll>
            <FadeInOnScroll>
                <Eventbody></Eventbody>
            </FadeInOnScroll>

        </div>
    );
}

export default Events;