
function Timetable() {
    return(
        <div class="container mt-5">
            <div class="timetable-wrapper mt-5">
	            <h2 class="mt-5 mb-5 gam-ts3 text-center"><strong>Events und Programm</strong></h2>
	                <ol reversed start="20">
		<li>
			<span>Porgrammpunkt 1</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 2</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 3</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 4</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 5</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 6</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 7</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 8</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 9</span>
			<span>Kurze Beschreibung</span>
		</li>
		<li>
			<span>Programmpunkt 10</span>
			<span>Kurze Beschreibung</span>
		</li>
	</ol>
	
	{/* <div class="divider"></div> */}
	
</div>
        </div>


    )
}

export default Timetable;