import SebSieb from '../images/Siebrecht-Big.jpg'
import background8 from '../images/Background-8.jpg'
import background7 from '../images/Background-7.jpg'
function TurnierArticles(){
    return (
<div class="container mt-5">
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <img src={SebSieb} class="card-img-top" alt="..."></img>
        <div class="card-body">
          <h5 class="card-title">Turnierleiter "Sebastian Siebrecht" </h5>
          <p class="card-text">Hier steht ein Vorschau Text über Sebastian Siebrecht. Wenn man auf "Mehr erfahren" klickt, werden weitere Informationen angezeigt</p>
          <a href="#" class="btn btn-primary">Mehr erfahren</a>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <img src={background8} class="card-img-top" alt="..."></img>
        <div class="card-body">
          <h5 class="card-title">Turnierkonzept und Besonderheiten</h5>
          <p class="card-text">Hier steht ein Vorschautext für die Kurzvorstellung des Turnierkonzeptes und seiner Besonderheiten</p>
          <a href="#" class="btn btn-primary">Mehr erfahren</a>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <img src={background7} class="card-img-top" alt="..."></img>
        <div class="card-body">
          <h5 class="card-title">Vision und strategische Ziele</h5>
          <p class="card-text">Hier ist ein Vorschautext für die vision und strategischen Ziele des Turnieres zu sehen. Wenn man auf "Mehr erfahren" klickt, kann man weitere Informationen dazu erhalten</p>
          <a href="#" class="btn btn-primary">Mehr erfahren</a>
        </div>
      </div>
    </div>
  </div>
</div>


    );
}

export default TurnierArticles;