import schach1 from "../images/Schach1.jpg";
import schach2 from "../images/schach2.jpg";
import schach3 from "../images/schach3.jpg";

function CommunityArticles(){
    return(
        <div class="container mt-5">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <img src={schach1} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                                <h5 class="card-title">Workshops und Seminare</h5>
                                <p class="card-text">Hier ist ein Vorschautext. Wenn man auf "Mehr erfahren" klickt, kann man weitere Informationen dazu erhalten</p>
                                <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img src={schach2} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                                <h5 class="card-title">Schweizer Schachverband</h5>
                                <p class="card-text">Hier ist ein Vorschautext. Wenn man auf "Mehr erfahren" klickt, kann man weitere Informationen dazu erhalten</p>
                                <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img src={schach3} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                                <h5 class="card-title">Partnerkontakte und Blog</h5>
                                <p class="card-text">Hier ist ein Vorschautext. Wenn man auf "Mehr erfahren" klickt, kann man weitere Informationen dazu erhalten</p>
                                <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default CommunityArticles;
