import RedBullLogo from '../images/Red-Bull-logo.png';
function SponsorenList(){
    return(
        <div class="container">
        <div class="row">
        <div class="col-md-4">
          <div class="card">
            <img src={RedBullLogo} class="card-img-top" alt="..."></img>
            <div class="card-body">
              <a href="#" class="btn btn-primary">Mehr erfahren</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <img src={RedBullLogo} class="card-img-top" alt="..."></img>
            <div class="card-body">
              <a href="#" class="btn btn-primary">Mehr erfahren</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <img src={RedBullLogo} class="card-img-top" alt="..."></img>
            <div class="card-body">
              <a href="#" class="btn btn-primary">Mehr erfahren</a>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
}

export default SponsorenList;