import eventzentrum from "../images/Eventzentrum.jpg";
import background from "../images/Background.jpg";
import background5 from "../images/Background-4.jpg";

function Eventbody(){
    return(

        <div class="container">
            <div class="row mt-5 mb-5">
                    <div class="col-md-4">
                        <div class="card">
                            <img src={eventzentrum} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                            <h5 class="card-title">Eröffnungsgala und VIP Events </h5>
                            <p class="card-text">Hier steht ein Vorschau Text. Wenn man auf "Mehr erfahren" klickt, werden weitere Informationen angezeigt</p>
                            <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="card">
                            <img src={background} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                            <h5 class="card-title">Blitzturniere</h5>
                            <p class="card-text">Hier steht ein Vorschau Text. Wenn man auf "Mehr erfahren" klickt, werden weitere Informationen angezeigt</p>
                            <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img src={background5} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                            <h5 class="card-title">Bilder und Videos</h5>
                            <p class="card-text">Hier steht ein Vorschau Text. Wenn man auf "Mehr erfahren" klickt, werden weitere Informationen angezeigt</p>
                            <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                    </div>
                    </div>

        </div>
    )
}

export default Eventbody;