import Turnier from "./Turnier/Turnier";
import Events from "./Events/Events";
import Sponsoren from "./Sponsoren/Sponsoren";
import Vip from "./VIP/Vip";
import Medien from "./Medien/Medien";
import Community from "./Community/Community";



function Newbody() {
    return (

        <div>
            <Turnier></Turnier>
            <Events></Events>
            <Sponsoren></Sponsoren>
            <Vip></Vip>
            <Medien></Medien>
            <Community></Community>
        </div>
    );
}

export default Newbody;