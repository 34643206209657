import './App.css';
import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import Newbody from './Newbody/newbody';
import FadeInOnScroll from './FadeInOnScroll';

function App() {
  return (
    <div className="App">

    <Header></Header>
    <Newbody></Newbody>
    <FadeInOnScroll>
    <Footer></Footer>
    </FadeInOnScroll>

      {/* <Body></Body> */}

      </div>
  );
}

export default App;
