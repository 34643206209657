import './Header.css';
import dummyHdImage from "./dummy-hd.jpg";
import Navbar from './Navbar';
import '../bootstrap.min.css';
import Logo from "./Logo.png";

function Header(){
    return (
        <div class="Header-Background">
            <div class="container desktop Header-Container">
                <div class="row justify-content-end align-items-center Header-Container me-5">
                    <div class="col-5 text-center wiggle-animation">

                        <div class="Logo-Header ">
                            <img src={Logo}></img>
                        </div>
                        <h4>23.11 bis 01.12.2024</h4>
                    <div class="Header-Button-Container bp-md-p">
                        <div class="Box-Button-Left bp-md-p">
                            <button class="button-left">LIVESTREAM</button>
                        </div>
                        <div class="Box-Button-Right bp-md-p">
                            <button class="button-right">SPIELERPROFILE</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="container mobile Header-Container">
                <div class="row justify-content-center Header-Container align-items-center">
                    <div class="col-8 wiggle-animation">
                    <div class="Logo-Header ">
                            <img src={Logo}></img>
                        </div>
                        <h4 class="text-white">23.11 bis 01.12.2024</h4>
                    </div>
                </div>

            </div>
            <div class="Wave-SVG">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,320L60,314.7C120,309,240,299,360,272C480,245,600,203,720,208C840,213,960,267,1080,282.7C1200,299,1320,277,1380,266.7L1440,256L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
        </div>
    );
}

export default Header;