import FadeInOnScroll from "../../FadeInOnScroll";
import "../Turnier/Turnier.css";
import SponsorenList from "./SponsorenList";
import SponsorenTeaser from "./SponsorenTeaser";


function Sponsoren() {
    return (

        <div>
            <FadeInOnScroll>
            <SponsorenTeaser></SponsorenTeaser>
            </FadeInOnScroll>
            <FadeInOnScroll>
            <SponsorenList></SponsorenList>
            </FadeInOnScroll>

        </div>
    );
}

export default Sponsoren;