import './Footer.css';
import Logo from "../Header/Logo.png";

function Footer(){
    return(
        <footer class="text-center text-lg-start text-white bg-white">
            <div class="container p-4 pb-3">
                <section class="">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                            <div class="Logo-Footer">
                                <img src={Logo}></img>
                            </div>
                        </div>
                        <hr class="w-100 clearfix d-md-none" />


          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3 line-hight-responsive">
            <h6 class="text-uppercase mb-4 font-weight-bold">Kontakt</h6>
            <p><i class="fas fa-home mr-3"></i> Eckel Presse & PR GmbH</p>
            <p><i class="fas fa-envelope mr-3"></i>Vert. Thomas Brunke</p>
            <p><i class="fas fa-phone mr-3"></i>Knesebeckstraße 54</p>
            <p><i class="fas fa-print mr-3"></i>10719 Berlin, Deutschland</p>
            <p><i class="fas fa-print mr-3"></i>Tel.: +49 30 88912988</p>
            <p><i class="fas fa-print mr-3"></i>Mobil: +49 172 5237010</p>
            <p><i class="fas fa-print mr-3"></i>E-Mail: info@e-pr.de</p>
          </div>
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
          <p>
              <a class="" href="https://www.e-pr.de/impressum/">FAQ</a>
            </p>
            <p>
              <a class="" href="https://www.e-pr.de/impressum/">Nutzungsanleitung</a>
            </p>
                            <h6 class="text-uppercase mb-4 font-weight-bold">Rechtliches</h6>
            <p>
              <a class="" href="https://www.e-pr.de/impressum/">Impressum</a>
            </p>
            <p>
              <a class="" href="https://www.e-pr.de/datenschutzerklaerung-und-cookies/">Datenschutzerklärung</a>
            </p>
          </div>
        </div>
      </section>
{/*       <section class="p-3 pt-0">
        <div class="row d-flex align-items-center">
          <div class="col-md-7 col-lg-8 text-center text-md-start">
            <div class="p-3">
              © 2020 Copyright:
              <a class="" href="#"
                 > Gambarogno</a
                >
            </div>
          </div>

          <div class="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
            <a
               class="btn btn-outline-light btn-floating m-1"
               role="button"
               ><i class="fab fa-facebook-f"></i
              ></a>

            <a
               class="btn btn-outline-light btn-floating m-1"
               role="button"
               ><i class="fab fa-twitter"></i
              ></a>

            <a
               class="btn btn-outline-light btn-floating m-1"
               role="button"
               ><i class="fab fa-google"></i
              ></a>

            <a
               class="btn btn-outline-light btn-floating m-1"
               role="button"
               ><i class="fab fa-instagram"></i
              ></a>
          </div>
        </div>
      </section> */}
    </div>
  </footer>
    );
}

export default Footer;