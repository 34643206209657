import FadeInOnScroll from "../../FadeInOnScroll";
import "../Turnier/Turnier.css";
import livestream from "../images/livestream.jpg";
import presse from "../images/presse.jpg";
import biggreek from "../images/big-greek.jpg";


function Medien() {
    return (

        <div>
            <FadeInOnScroll>
                <div class="container mt-5">
                <h2 class="mt-5 mb-5 gam-ts3 text-center"><strong>Medien und Übertragung</strong></h2>
                    <div class="row">
                        <div class="col-md-4">
                        <div class="card">
                            <img src={livestream} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                            <h5 class="card-title">Online-Übertragung</h5>
                            <p class="card-text">Hier ist ein Vorschautext. Wenn man auf "Mehr erfahren" klickt, kann man weitere Informationen dazu erhalten</p>
                            <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="card">
                            <img src={presse} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                            <h5 class="card-title">Pressemitteilungen</h5>
                            <p class="card-text">Hier ist ein Vorschautext. Wenn man auf "Mehr erfahren" klickt, kann man weitere Informationen dazu erhalten</p>
                            <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="card">
                            <img src={biggreek} class="card-img-top" alt="..."></img>
                            <div class="card-body">
                            <h5 class="card-title">Medienpartner Big Greek</h5>
                            <p class="card-text">Hier ist ein Vorschautext. Wenn man auf "Mehr erfahren" klickt, kann man weitere Informationen dazu erhalten</p>
                            <a href="#" class="btn btn-primary">Mehr erfahren</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </FadeInOnScroll>

        </div>
    );
}

export default Medien;