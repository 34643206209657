import TurnierArticles from "./TurnierArticles";
import TurnierLocation from "./TurnierLocation";
import TurnierTeaser from "./TurnierTeaser";
import FadeInOnScroll from "../../FadeInOnScroll";
import './Turnier.css'


function Turnier() {
    return (

        <div>
            <FadeInOnScroll>
            <TurnierTeaser></TurnierTeaser>
            </FadeInOnScroll>
            <FadeInOnScroll>
            <TurnierArticles></TurnierArticles>
            </FadeInOnScroll>
            <FadeInOnScroll>
            <TurnierLocation></TurnierLocation>
            </FadeInOnScroll>
        </div>
    );
}

export default Turnier;