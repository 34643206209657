import React, { useEffect, useRef, useState } from 'react';

const FadeInOnScroll = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null, // default to viewport
        rootMargin: '0px',
        threshold: 0.1 // 10% des Elements muss sichtbar sein
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{
        opacity: 0,
        transition: 'opacity 1s ease-out',
        ...(isVisible && { opacity: 1 })
      }}
    >
      {children}
    </div>
  );
};

export default FadeInOnScroll;
